import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'common/Image';
import FooterNav from 'components/FooterNav';
import { FooterProps, FooterQuery } from './models';
import { getUrl, getTarget } from '../../utils/browser';
import './Footer.scss';

const Footer: FC<FooterProps> = () => {
  const {
    footer: { legals, copyright, logoItems, navs, socialMedia },
  }: FooterQuery = useStaticQuery(graphql`
    {
      footer {
        logoItems {
          image {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 150)
              }
            }
            mobile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 150)
              }
            }
            tablet {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 150)
              }
            }
          }
          link {
            name
            url
            queryString
            target
          }
        }
        navs {
          name
          url
          queryString
          target
        }
        socialMedia {
          name
          url
          queryString
          target
        }
        legals
        copyright
      }
    }
  `);

  return (
    <footer id="footer">
      <div className="container">
        <div className="row py-3 mx-0 mx-md-5 logos">
          {logoItems.map(({ image, link: [link] }, index) => (
            <div className="col px-0 d-flex align-items-center col-logo" key={index}>
              <div className="border-right px-2 w-100 text-center">
                <a
                  href={getUrl(link)}
                  target={getTarget(link)}
                  data-testid="footer-logo-item"
                  title={image.altText}
                >
                  <Image imageData={image} alt={image.altText} />
                </a>
              </div>
            </div>
          ))}
          <FooterNav navs={navs} socialMedia={socialMedia} />
        </div>
        <div className="row">
          <div className="col">
            <div className="legals text-center">{legals}</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <small className="text-center d-block my-4">{copyright}</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
