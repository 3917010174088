import React, { FC } from 'react';
import classNames from 'classnames';
import loadable from '@loadable/component';

import Loadable from 'components/Loadable';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className }) => {
  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const shouldLoadPreviewWidget = Boolean(Number(process.env.ENABLE_GATSBY_REFRESH_ENDPOINT));

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>

      <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />

      <Header />

      <main id="main" className="layout__main">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
