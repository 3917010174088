import React, { FC } from 'react';
import { getUrl, getTarget } from '../../utils/browser';
import { FooterNavProps } from './models';

import './FooterNav.scss';

const FooterNav: FC<FooterNavProps> = ({ navs, socialMedia }) => (
  <div className="col-12 col-md-4 px-0 d-flex align-items-center mt-3 mt-md-">
    <ul className="list-unstyled boder-left-0 border-left-md-1 pl-0 pl-md-5 pb-4 pb-md-0 text-center text-md-left w-100 links">
      {navs.map((navItem, index) => (
        <li className="item" key={index}>
          <a href={getUrl(navItem)} target={getTarget(navItem)} data-testid="footer-nav-item">
            {navItem.name}
          </a>
        </li>
      ))}
      <li className="icon pt-2 pt-md-0" key="social">
        {socialMedia.map((mediaItem, index) => (
          <a
            href={getUrl(mediaItem)}
            target={getTarget(mediaItem)}
            key={index}
            className="mr-2"
            title={mediaItem.name}
            data-testid="footer-nav-social"
          >
            <i className={`fab fa-${mediaItem.name}`} />
          </a>
        ))}
      </li>
    </ul>
  </div>
);

export default FooterNav;
