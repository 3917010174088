import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'common/Image';
import { HeaderProps, HeaderQuery } from './models';
import { getUrl, getTarget } from '../../utils/browser';

import './Header.scss';

const Header: FC<HeaderProps> = () => {
  const {
    header: { logo, navs, socialMedia },
  }: HeaderQuery = useStaticQuery(graphql`
    {
      header {
        logo {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
        }
        socialMedia {
          name
          url
          target
          queryString
        }
        navs {
          name
          url
          target
          queryString
        }
      }
    }
  `);

  const [expanded, setExpanded] = React.useState(false);

  return (
    <header className="fixed-top">
      <Navbar expand="md" className="py-0" expanded={expanded}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>
          <span className="fas fa-bars" />
        </Navbar.Toggle>
        <Navbar.Brand href="/" className="mx-auto mx-md-0">
          <Image imageData={logo} alt={logo.altText} />
        </Navbar.Brand>
        <Navbar.Collapse id="menu">
          <Nav className="mr-auto" />
          <Nav className="navbar-nav my-2 my-md-0">
            {navs.map((navItem, index) => (
              <Nav.Link
                href={getUrl(navItem)}
                target={getTarget(navItem)}
                key={index}
                className="text-uppercase"
                title={navItem.name}
                data-testid="header-nav-link"
                onClick={() => setExpanded(!expanded)}
              >
                {navItem.name}
              </Nav.Link>
            ))}
          </Nav>
          <Nav className="d-block text-center py-4 py-md-0">
            {socialMedia.map((mediaItem, index) => (
              <a
                href={getUrl(mediaItem)}
                target={getTarget(mediaItem)}
                key={index}
                className="nav-icon mx-1"
                data-testid="header-nav-social"
                title={mediaItem.name}
              >
                <i className={`fab fa-${mediaItem.name}`} />
              </a>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
